export const songList = {
	"Buddhists and Bankers": [
        {name: "My Golden",duration: "3:58",url: "http://www.elavatar.com/music/MyGolden.mp3"},
        {name: "Beyond Regrets",duration: "1:58",url: "http://www.elavatar.com/music/Beyond%20Regrets.mp3"},
        {name: "Please Come Home",duration: "4:58",url: "http://www.elavatar.com/music/PleaseComeHome.mp3"},
        {name: "Rain Called Love",duration: "4:18",url: "http://www.elavatar.com/music/Rain%20Called%20Love.mp3"},
        {name: "She Brings Me Colors",duration: "2:44",url: "http://www.elavatar.com/music/She%20Brings%20Me%20Colors.mp3"},
        {name: "Wonder Waltz",duration: "3:34",url: "http://www.elavatar.com/music/Wonder%20Waltz.mp3"},
        {name: "When I Lost Everything",duration: "344",url: "http://www.elavatar.com/music/When%20I%20Lost%20Everything.mp3"}
    ],
	"The Rooftop Life": [
	    {name: "Clear To Me",duration: "4:11",url: "http://www.elavatar.com/music/ClearToMe.mp3"},
	    {name: "Not Tonight (Oh I Know)",duration: "3:47",url: "http://www.elavatar.com/music/OhIKnow_3.mp3"},
	    {name: "I Believe In Everything",duration: "3:14",url: "http://www.elavatar.com/music/IBelieveInEverything.mp3"},
	    {name: "Hail Mary",duration: "4:16",url: "http://www.elavatar.com/music/HeyMary.mp3"},
	    {name: "No Way",duration: "4:46",url: "http://www.elavatar.com/music/NoWay48Drums.mp3"},
	    {name: "What I Wouldn't Do",duration: "3:22",url: "http://www.elavatar.com/music/WhatIWouldntDo.mp3"},
	    {name: "Welcome Today",duration: "3:09",url: "http://www.elavatar.com/music/WelcomeToday2.mp3"},
	    {name: "Shame",duration: "3:58",url: "http://www.elavatar.com/music/Shame.mp3"},
	    {name: "Let Me Go Down",duration: "5:04",url: "http://www.elavatar.com/music/LetMeGoDown5.mp3"},
	    {name: "Say You Will",duration: "4:33",url: "http://www.elavatar.com/music/sayyouwill.mp3"}

	],
 	"Invisible Skin": [
 		{name: "Hundred Years",duration: "3:01",url: "http://www.elavatar.com/music/HundredYears.mp3"},
		{name: "I Hurt",duration: "3:01",url: "http://www.elavatar.com/music/IHurt.mp3"},
		{name: "Walk The World",duration: "4:43",url: "http://www.elavatar.com/music/WalkTheWorld.mp3"},
	    {name: "For Free",duration: "4:06",url: "http://www.elavatar.com/music/ForFree.mp3"},
	    {name: "Bad Johnny",duration: "4:43",url: "http://www.elavatar.com/music/BadJohnny.mp3"},
	    {name: "Invisible Skin",duration: "3:42",url: "http://www.elavatar.com/music/InvisibleSkin.mp3"},
	    {name: "All My Friends",duration: "3:17",url: "http://www.elavatar.com/music/AllMyFriends.mp3"}
	]
}
