export const songFeatures = {
	"Stardust":{"players":[],"notes":""},
	"Walk The World": {"players":["Robin Luongo - Co-Writer"],"notes":""},
	"For Free": {"players":["Robin Luongo - Cello, Bass, Graham Gulian - Drums"],"notes":""},
	"Bad Johnny": {"players":[],"notes":"Yeah, he's bad."},
	"Invisible Skin":{"players":[],"notes":""},
	"All My Friends":{"players":[],"notes":""},
	"I Hurt":{"players":[],"notes":""},
	"Hundred Years":{"players":[],"notes":""},
	"I Believe In Everything":{"players":["Robin Luongo - Cello","Nina Luongo - Vocals"],"notes":"Live!"}
}
