import React from 'react';


export default class Contact extends React.Component {

	handleClick(e){
		this.props.onClick(e.currentTarget.innerText);
	}

	render() {

		return (
			
			<div className="list-group">
			<h2>Contact Bob</h2> 
			<h3><a href="mailto::admin@bobgulian.com">email: admin@bobgulian.com</a></h3>
			<div id="contact-div">
			
			
			</div>

			</div>
			
			)


	}

		

};

