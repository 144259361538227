export const albums = [
	{
		name: 'Invisible Skin',
		pictureUrl: 'mask.png',
		songs: [],
		videos: []
	},
	 {
	 	name: 'The Rooftop Life',
	 	pictureUrl: 'rooftoplife.png',
	 	songs: [],
		videos: []
	 },
	 {
	 	name: 'Buddhists and Bankers',
	 	pictureUrl: 'BuddhistsAndBankers.png',
	 	songs: [],
		videos: []
	 }
]