import React from 'react';


export default class CoverPage extends React.Component {

	handleClick(e){
		this.props.onClick(e.currentTarget.innerText);
	}

	render() {

		var topp = require("../assets/images/Thirty3-top.jpg");
		var midp = require("../assets/images/Thirty3-mid.jpg");
		var botp = require("../assets/images/Thirty3-bottom.jpg");
		//547x1393, 250x637
		return (
			<div >
			<div className='col-lg-4'>
			<a href="https://bobgulian.hearnow.com">
					
			<div>
				<div className='row'>

					<div className='col-lg-8'>
					<img src={topp} width='509' height='200' />
					</div>
					

				</div>
				<div className='row'>
					
					<div className='col-lg-12 indent-strip' >
						<img src={midp} width='509' height='200' />
					</div>

				</div>
				<div className='row'>
					<div className='col-lg-1' />
					<div className='col-lg-11'>
						<img src={botp} width='509' height='200'/>
					</div>
				</div>
			</div>
			</a>
			</div>
			</div>
			)


	}



};

