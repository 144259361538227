import React from 'react';


export default class SongItem extends React.Component {


	handleClick(e){
		var songTitle = e.currentTarget.innerText.split(" - ")[0];
		this.props.onClick(songTitle);
	}

	componentDidMount() {

	}

	componentWillReceiveProps(nextProps) {

	}

	render(){

		var cn = "list-group-item song-list";

		if(this.props.active){
			cn += " ";
		}

		if (this.props.playSrc) {
			return (
				<div className={cn} className="playlist">
					<p>{this.props.name}</p>
    				<audio controls autoplay="autoplay" ref="audio_tag" src={this.props.playSrc}/>
				</div>
				)
		} else {
			return (
				<a className={cn} onClick={this.handleClick}>
					{this.props.name} - {this.props.duration}
				</a>
			)
		}
	}

};

