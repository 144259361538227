import React from 'react';


export default class AboutPage extends React.Component {

	handleClick(e){
		this.props.onClick(e.currentTarget.innerText);
	}

	render() {

		var bobpic = require("../assets/images/bob-creative-thought.jpg");
		var neenzpic = require("../assets/images/neenz.jpg");
		var robmakpic = require("../assets/images/rob-mak-studio.jpg");
		return (

			<div className='list-group' >
			<h2>About Page</h2>
			<div id="bob-div" >
			Bob Gulian - born NYC, lived NYC and other places. Lead bands in NYC, Boston and LA and busked in the Paris Metro for two years. He's currently playing around solo and in the band,
			<h3 id="lazy-girl">LaZ Girl And The Recliners</h3>
			Here's some pictures of the studio and the recliners.

			</div>
			<div id="about-div" >
				<div className='row'>
					<div className='col-lg-5'>
					<img src={bobpic} width='400' height='500' />
					</div>
					<div className='col-lg-7'>
					<img src={neenzpic} />
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-12'>
						<img src={robmakpic} />
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-12'>
						<h4>It's white</h4>
					</div>
				</div>
			</div>
			</div>
			)


	}



};

