import React from 'react';
import Album from './album';
import {albums} from '../assets/audio/albumsData';


export default class Albums  extends React.Component {

	reorderAlbums(firstName) {
		
	}

	render(){
		let self = this;
		let activeAlbumObjIndex=null;
		let otherIndexes = [];
		let albumObjs = albums.map( (l,index) => {
			var active = false;
			if (l.name == this.props.activeAlbum) {
				activeAlbumObjIndex = index;
				active = true;
			} else {
				otherIndexes.push(index);
			}

			return <Album name={l.name} cover={l.pictureUrl}
					active={active} onClick={self.props.onClick} />
		});

		if(!albumObjs.length){
			return null;
		}

		return (
			<div className="list-group col-xs-12">
				<div className="list-group-item">
					<div className="row">
						<div className="col-xs-12 current-album">
						{albumObjs[activeAlbumObjIndex]}
						</div>
					</div>
					<div className="row">
						<div className="col-xs-6 small-album">
						{albumObjs[otherIndexes[0]]}
						</div>
						<div className="col-xs-6 small-album">
						{albumObjs[otherIndexes[1]]}
						</div>
					</div>
				</div>
			</div>
		)

	}

};
