import React from 'react';
import {songList} from '../assets/audio/songlist.js';
import {songFeatures} from '../assets/audio/songFeatures.js';
import {albums} from '../assets/audio/albumsData';

export default class PlayOneSong extends React.Component {

  handleClick(e){
    this.props.onClick(e);
  }
  handleAlbumClick() {

  }

  findSong() {
     var self = this;
     var retSong = null;
     var SO = Object.keys(songList).filter((theKey) => {
        var theSong = songList[theKey].filter((theSongObj) => {
          if (self.props.name == theSongObj.name) {
            return theSongObj = Object.assign(theSongObj,{"album":theKey});
          } else {
            return false;
          }
        })
        if (theSong.length > 0) {
          retSong = theSong;
          return true;
        } else {
          return false;
        }
     })
     return retSong;
  }

  render(){
    var cn = ""; //"active-location list-group-item";

    var song = this.findSong()[0];
    var album = albums.find((ele, index, albums) => {
       if (ele.name === song.album) {
          return true
       } else {
          return false;
       }
    });
    var w = "325", h = "325";
    
    var path = require("../assets/images/" + album.pictureUrl);
    return (
      
        <div className={cn} className="playlist">
          <a href="/" >
          <img src={path} width={w} height={h}/>
          </a>
          <h3 className="primary">{this.props.name} - {song.duration}</h3>
          <h4>{songFeatures[song.name]}</h4>
          <div className="audio-player">
            <audio controls autoplay="true" ref="audio_tag" src={song.url}/>
          </div>
        </div>   
     
    )
  }

};

