import React from 'react';
import SongList from './SongList';
import Albums from './albums';
import AboutPage from './about_page';
import Contact from './contact';
import Cover from './cover';
import PlayOneSong  from './playonesong';
import '../App.css';


export default class BGApp extends React.Component {

	constructor(props) {
     super(props);

     // Extract the favorite locations from local storage

		var favorites = [];
		var oneOff = "";

		if(localStorage.favorites){
			favorites = JSON.parse(localStorage.favorites);
		}

		if (this.props.location &&
			 this.props.location.pathname.match(/\/song\//)) {
			 oneOff = decodeURIComponent(this.props.location.pathname.split("/")[2]);
		}
         this.state = {
            albumActive: "Invisible Skin",
			songIndex: 0,
			songPlay: "",
			oneOff: (oneOff === ""?false:oneOff),
			favorites: favorites,
			contact: false,
			about: false,
			home: false,
			cover: false
         };
     }
	

	componentWillMount() {
		if (!this.state.oneOff) {
			this.setState ({
				cover: true
			});

		}
	}

	_doAbout(e) {
		this.setState ({
			contact: false,
			home: false,
			about: true,
			oneOff: false,
			cover: false
		})
	}

	_doContact(e) {
		this.setState ({
			contact: true,
			home: false,
			about: false,
			oneOff: false,
			cover: false
		})
	}

	_doPlay(url) {
		const elem = document.getElementbById('audio_tag')
		elem.setAttribute('src',url)
		elem.play()
	}

	_doStopPlay() {
		const elem = document.getElementbById('audio_tag')
		elem.removeAttribute('src')
	}
	_doHome(e) {
		
		this.setState ({
			contact: false,
			home: true,
			oneOff: false,
			about: false,
			cover: false
		});
	}

	_doCover(e) {
		this.setState ({
			contact: false,
			home: false,
			oneOff: false,
			about: false,
			cover: true
		});
	}

	toggleFavorite(address){
		/*
		<nav>
					<ul className="">
						<li  className={this.state.cover?"active":""}><a href="#" onClick={this._doCover.bind(this)}>Home</a></li>
			            <li  className={this.state.home?"active":""}><a href="#" onClick={this._doHome.bind(this)}>Yesterday's Weather</a></li>
			            <li  className={this.state.about?"active":""}><a href="#" onClick={this._doAbout.bind(this)}>About</a></li>
			            <li className={this.state.contact?"active":""}><a href="#" onClick={this._doContact.bind(this)}>Contact</a></li>
          			</ul>
        		</nav>
        */

	}

	_renderHome() {

		if (!this.state.home) return false;
		return(
			<div className="row maincolumns">
				<div className="col-lg-5">
				<SongList
					onClick={this.handleSongClick} activeAlbum={this.state.albumActive} activeSongIndex={this.state.activeSongIndex} playSong={this.state.songPlay}/>
				</div>
				<div className="col-lg-7">
				<Albums onClick={this.handleAlbumClick} activeAlbum={this.state.albumActive}/>
				</div>
			</div>
			)
	}

	_renderAbout() {
		if (!this.state.about) return false;
		return(
		<div className="row">
			<AboutPage />
		</div>
		)
	}

	_renderContact() {
		if (!this.state.contact) return false;
		return(
		<div className="row">
			<Contact />
		</div>
		)
	}

	_renderCover() {
		if (!this.state.cover) return false;
		return(
		<div className="row">
			<Cover />
		</div>
		)
	}
	_renderOneOff() {
		if (!this.state.oneOff) return false;
		return (
			<div className="row">
			<div className="col-lg-7">
			<PlayOneSong name={this.state.oneOff} />
			</div>
			<div className="col-lg-5">
			</div>
			</div>
		)

	}
	
	handleSongClick(songName){

		this.setState({
			songPlay: songName
		})
	}

	handleAlbumClick(albumName){

		this.setState({
			albumActive: albumName
		})
	}

	render(){
		
		return (

			<div>
				<div className="header clearfix">
				 
        		<audio ref="audio_tag"  id="audio_tag"/>
        		<h3 className="text-muted"></h3>
      			</div>
      			<div className="whatevs">
					<h1>Bob Gulian</h1>
				</div>
				{this._renderCover()}
				{this._renderHome()}
				{this._renderAbout()}
				{this._renderContact()}
				{this._renderOneOff()}
				<footer className="footer">
        			<p>&copy; 2022 Elavatar</p>
      		</footer>
      		<p>And furthermore...</p><a  className="appLink" href="http://www.deeplakesound.com">Deep Lake Sound Studio</a>
			</div>

		);
	}

};


