import logo from './logo.svg';
import './App.css';
import BGApp from "./components/bgapp"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BGApp />
      </header>
    </div>
  );
}

export default App;
