import React from 'react';

export default class Album  extends React.Component {


	handleClick(e){
		var name = e.currentTarget.children[1].innerText;
		this.props.onClick(name);
	}

	render(){

		var cn = "list-group-item album-item"; //"list-group-item";

		if(this.props.active){
			cn += " active-location";
		}
		var sw = "158", sh = "158";
		var w = "275", h = "275";
		var tWidth,tHeight;


		if(this.props.active){
			tWidth = w;
			tHeight = h;
		} else {
			tWidth = sw;
			tHeight = sh;
		}

		var path = require("../assets/images/" + this.props.cover);
		return (
			<a className={cn} onClick={this.handleClick}>
				<img src={path} width={tWidth} height={tHeight} />
				<p>{this.props.name}</p>
			</a>
		)

	}

};
