import React from 'react';
import SongItem  from './SongItem';
import {songList} from '../assets/audio/songlist.js';

export default class  SongList extends React.Component {

	render(){

		var self = this;

		var songs = songList[this.props.activeAlbum].map(function(l,index){
			var active = false;
			if (index == 0) active = true;
			if (self.props.playSong == l.name) {
				return <SongItem name={l.name}
					active={active} playSong={self.props.playSong} onClick={self.props.onClick} 
					playSrc={l.url}/>

			} else {
				return <SongItem name={l.name} duration={l.duration} active={active} onClick={self.props.onClick} />
			}
		});

		if(!songs.length){
			return null;
		}

		return (
			<div className="list-group col-xs-12">
				<span className="list-group-item active">Song List - {this.props.activeAlbum}</span>
				{songs}
			</div>
		)

	}

};


